import {
  createTelemetry,
  trace,
  logs as wpLogs,
  metrics as wpMetrics,
} from '@web-platform/telemetry';

import { config } from '@/config';

const ENABLE = config.ENABLE_MONITORING;
const BASE_URL = config.TELEMETRY_BASE_URL;
const SERVICE_NAME = config.TELEMETRY_SERVICE_NAME;
const DEBUG_MODE = config.TELEMETRY_DEBUG_MODE;
const DEV_MODE = config.TELEMETRY_DEV_MODE;
const ENVIRONMENT = config.APP_ENVIRONMENT;

let telemetry: { logging?: any; metrics?: any; tracing?: any } = {};

if (typeof window !== 'undefined') {
  telemetry = createTelemetry({
    collectorBaseUrl: BASE_URL,
    serviceName: SERVICE_NAME,
    showInBrowserConsole: DEBUG_MODE,
    devMode: DEV_MODE,
    environment: ENVIRONMENT,
  });
}

export const { metrics, tracing } = telemetry;

const fallbackLogger = {
  log: console.log,
  warn: console.warn,
  error: console.error,
  info: console.info,
  debug: console.debug,
};

export const logging = telemetry.logging ?? { setup: () => fallbackLogger };

if (!ENABLE) {
  wpMetrics.disable();
  wpLogs.disable();
  trace.disable();
}

export const logger = logging.setup('logs');
