export function getAppEnvironment():
  | 'dev'
  | 'staging'
  | 'beta'
  | 'prod'
  | 'local'
  | undefined {
  let baseUrl = '';

  if (typeof window !== 'undefined') {
    baseUrl = window.location.href;
  } else {
    baseUrl = process.env.NEXT_PUBLIC_BASE_URL ?? '';
  }

  if (!baseUrl) return undefined;

  if (baseUrl.includes('development')) return 'dev';
  if (baseUrl.includes('staging')) return 'staging';
  if (baseUrl.includes('beta')) return 'beta';
  if (baseUrl.includes('localhost')) return 'local';

  return 'prod';
}
